import React from "react"
import Seo from '../components/seo';

const Flex = (props) => {
  let data = props.pageContext.data
  let content = data.body;
  if (content !== null) {
    // Set correct url.
    content = content.processed.replace(/\/sites/g, `${process.env.BACKEND_URL}/sites`);
    content = content.replace(/\/media\/oembed/g, `${process.env.BACKEND_URL}/media/oembed`);
    content = { __html: content };
  }

  return (
    <>
      <Seo
        title={data.title}
        pathname={data.path.alias}
        article={false}
      />
      <div className="page__wrapper">
        <h1 className="page__title">{data.title}</h1>
        <div className="page__block" dangerouslySetInnerHTML={content}></div>
      </div>
    </>
  );
}

export default Flex
